import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

// Components
import ContentComponent from "../components/ContentComponent";
import FaqComponent from "../components/FaqComponent";
import HowToComponent from "../components/HowToComponent";
import AccordionComponent from "../components/AccordionComponent";
import CasinosList from "../components/CasinosList";
import CtaComponent from "../components/CallToActionComponents";
import FeaturedPosts from "../components/FeaturedPosts";
import GameOfTheMonth from "../components/GameOfTheMonth";
import HeroSection from "../components/HeroSection";
import BonusList from "../components/BonusesList";
import Seo from "../components/Seo";

import {
  getFirstImageFromAllContentComponents,
  getFirstParagraphFromAllContentComponents,
} from "../utils/schemes";

const slugWithoutImages = [
  "contact-us",
  "cookie-policy",
  "privacy-policy",
  "terms-and-conditions",
];

const WebsitePage = ({ data, location }) => {
  const pageData = data?.contentfulWebsitePage;
  const [faqItems, setFaqItems] = useState(undefined);
  const [howToSchema, setHowToChema] = useState(undefined);
  const components = pageData?.components;

  const firstParagraph = getFirstParagraphFromAllContentComponents(
    components,
    data?.contentfulWebsitePage?.pageName === "Main Page" ? "home" : null
  );
  let firstImage;
  if (slugWithoutImages.includes(pageData.slug)) {
    firstImage =
      "https://images.ctfassets.net/jjvulm4qrypd/1TKz1Cm1eezsOwH4arDNju/f28792077b93d311c9841500192bd315/logo_d232883a.svg";
  } else {
    firstImage = getFirstImageFromAllContentComponents(components);
  }

  let techArticleSchema;
  if (pageData) {
    techArticleSchema = {
      title:
        data?.contentfulWebsitePage?.pageName === "Main Page"
          ? "The Ultimate Online Casino List: Reviews, Ratings & Rewards"
          : data?.contentfulWebsitePage?.pageName,
      imageUrl: firstImage,
      description: firstParagraph,
      datePublished: pageData.createdAt,
      dateModified: pageData.updatedAt,
      slug: pageData.slug,
    };
  }

  useEffect(() => {
    components.forEach((element) => {
      if (element.__typename === "ContentfulFaqComponent") {
        setFaqItems(element.faqItems);
      } else if (element.__typename === "ContentfulHowTo") {
        setHowToChema(element);
      }
    });
  }, [components]);
  return (
    <Layout location={location}>
      <div className="content container">
        {data?.contentfulWebsitePage?.pageName !== "Main Page" && (
          <div className="page-header">
            <h1 className="h1 header-pages">
              {data?.contentfulWebsitePage?.pageName}
            </h1>
          </div>
        )}
        {components?.map((component) => {
          switch (component.__typename) {
            case "ContentfulSeoComponent":
              return (
                <Seo
                  title={component?.seoTitle}
                  description={component?.seoDescription}
                  techArticleSchemaData={techArticleSchema}
                  fagSchemaItems={faqItems}
                  howToSchemaData={howToSchema}
                  url={
                    component?.seoSlug === "https://list.casino/"
                      ? `https://list.casino/`
                      : `https://list.casino/${component?.seoSlug}/`
                  }
                  canonical={
                    component?.seoSlug === "https://list.casino/"
                      ? `https://list.casino/`
                      : `https://list.casino/${component?.seoSlug}/`
                  }
                />
              );
            case "ContentfulHeroComponent":
              return (
                <HeroSection
                  title={component?.title}
                  casinosCadsData={component?.topCasinos}
                  backgroundColor={component?.backgroundColor}
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  pageName={data?.contentfulWebsitePage?.pageName}
                />
              );
            case "ContentfulAccordionComponent":
              return (
                <AccordionComponent
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  accordionText={
                    component?.accordionContent?.childMarkdownRemark?.html
                  }
                />
              );
            case "ContentfulRelatedBonuses":
              return (
                <BonusList
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  title={component?.title}
                  bonuses={component?.relatedBonusesList}
                />
              );
            case "ContentfulCallToAction":
              return (
                <CtaComponent
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  title={component?.title}
                  ctaCards={component?.callToActionCards}
                />
              );
            case "ContentfulGameOfTheMonth":
              return (
                <GameOfTheMonth
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  gameName={component?.gameName}
                  gameImage={component?.gameImage}
                  gameDescription={
                    component?.gameDescription?.childMarkdownRemark?.html
                  }
                  playGameLink={component?.playGameLink}
                  link={component?.link}
                />
              );
            case "ContentfulFeaturedPosts":
              return (
                <FeaturedPosts
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  title={component?.title}
                  postCards={component?.relatedPosts}
                />
              );
            case "ContentfulRelatedCasinosList":
              return (
                <CasinosList
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  title={component?.title}
                  casinosCadsData={component?.casinosList}
                />
              );
            case "ContentfulHowTo":
              return (
                <HowToComponent
                  title={component?.title}
                  description={component?.description}
                  duration={component?.duration}
                  estimatedCost={component?.estimatedCost}
                  estimatedCostUrrency={component?.estimatedCostUrrency}
                  howToCards={component?.howToCards}
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                />
              );
            case "ContentfulContentComponent":
              return (
                <ContentComponent
                  data={component?.contentText?.childMarkdownRemark?.html}
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                />
              );
            case "ContentfulFaqComponent":
              return (
                <FaqComponent
                  faqItems={component?.faqItems}
                  title={component?.title}
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                />
              );
            default:
              return <></>;
          }
        })}
      </div>
    </Layout>
  );
};

export default WebsitePage;

export const query = graphql`
  query page($id: String!) {
    contentfulWebsitePage(id: { eq: $id }) {
      id
      pageName
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      slug
      components {
        ... on ContentfulAccordionComponent {
          id
          __typename
          marginTop
          marginBottom
          accordionContent {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulSeoComponent {
          id
          __typename
          seoSlug
          seoTitle
          seoDescription
        }
        ... on ContentfulCallToAction {
          id
          __typename
          title
          marginTop
          marginBottom
          callToActionCards {
            title
            slug
            icon {
              file {
                url
              }
            }
          }
        }
        ... on ContentfulContentComponent {
          id
          __typename
          marginTop
          marginBottom
          contentText {
            contentText
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulFaqComponent {
          id
          __typename
          title
          marginTop
          marginBottom
          faqItems {
            faqAnswer {
              faqAnswer
              childMarkdownRemark {
                html
              }
            }
            faqQuestion
          }
        }
        ... on ContentfulRelatedBonuses {
          id
          __typename
          title
          marginTop
          marginBottom
          relatedBonusesList {
            name
            slug
            description
            code
            referralUrl
            bonusBackgroundColor
            bonusLogo {
              gatsbyImage(
                width: 120
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        ... on ContentfulFeaturedPosts {
          id
          __typename
          title
          marginTop
          marginBottom
          relatedPosts {
            title
            createdData(formatString: "DD.MM.YYYY")
            categories
            slug
            featuredImage {
              gatsbyImage(
                width: 400
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        ... on ContentfulGameOfTheMonth {
          id
          marginTop
          marginBottom
          __typename
          gameName
          link
          gameImage {
            gatsbyImage(
              width: 960
              quality: 100
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
          gameDescription {
            childMarkdownRemark {
              html
            }
          }
          playGameLink {
            linkText
            linkType
            linkRoute
          }
        }
        ... on ContentfulHeroComponent {
          id
          __typename
          title
          marginTop
          marginBottom
          backgroundColor
          topCasinos {
            casinoName
            shortDescription
            logoBackgroundColor
            slug
            categories
            referralUrl
            rating
            casinoAward
            awardTextColor
            logo {
              gatsbyImage(
                width: 120
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        ... on ContentfulHowTo {
          id
          __typename
          marginTop
          marginBottom
          title
          duration
          estimatedCost
          estimatedCostUrrency
          description {
            id
            childMarkdownRemark {
              html
            }
          }
          howToCards {
            title
            description
            labelText
            labelIndex
            image {
              gatsbyImage(
                width: 270
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
              file {
                url
              }
            }
          }
        }
        ... on ContentfulRelatedCasinosList {
          id
          __typename
          title
          marginTop
          marginBottom
          casinosList {
            casinoName
            shortDescription
            logoBackgroundColor
            slug
            categories
            referralUrl
            rating
            logo {
              gatsbyImage(
                width: 120
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

/*

... on ContentfulRelatedBonuses {
  id
  __typename
  title
  relatedBonusesList {
    name
    slug
    description
    code
    referralUrl
    bonusBackgroundColor
    bonusLogo {
      gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
    }
  }
}
*/
