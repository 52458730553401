import React from "react";
import { useInView } from "react-intersection-observer";
import BonusCard from "./BonusCard";

const BonusList = ({ title, bonuses }) => {
  const [ref, inView] = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });
  return (
    <section className="casino-section" ref={ref}>
      <div className="newest-bonuses casino-bonuses casino-columns">
        {title ? <h2 className="newest-bonuses__title h4">{title}</h2> : null}
        <div className="casino-bonuses__list casino-columns__list row">
          {bonuses?.length &&
            bonuses?.map((bonus) => {
              return (
                <BonusCard
                  key={bonus?.description}
                  name={bonus?.code}
                  logo={bonus?.bonusLogo}
                  bonusBackgroundColor={bonus?.bonusBackgroundColor}
                  description={bonus?.description}
                  referralUrl={bonus?.referralUrl}
                  inView={inView}
                  className="casino-bonuses__item col-6 col-sm-6 col-lg-4 col-xl-2"
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default BonusList;
